import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Contact from './pages/contact';

function App() {
  return (
    <div>
        <Router>
            <Navbar />
            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/contact' element={<Contact />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
