import React from 'react';
import './home.css'
 
const Home = () => {
    return (
        <>
            <section className="home">
                <div className="home-content">
                    <h1>Welcome to <span>polydatum</span></h1>
                    <div className="text-animate">
                        <h3>Expertise in the Data Cycle.</h3>
                        <h3>From Acquisition to Insight.</h3>
                    </div>
                    <p>At Polydatum, we are pioneers in the realm of data science and machine learning. Our team of seasoned experts harnesses the power of data to drive innovation, solve complex problems, and unlock new opportunities for your business. We specialize in transforming raw data into actionable insights, using advanced machine learning algorithms to predict trends, optimize operations, and personalize customer experiences. Our commitment to excellence and our deep understanding of the data landscape make us the ideal partner for your data-driven journey. With Polydatum, you're not just hiring a contractor, you're gaining a strategic partner dedicated to empowering your business with data. Let us help you turn your data into your most valuable asset.</p>

                    <div className="btn-box">
                        <a href='./contact' className="btn">Free Consultation</a>
                        <a href='./contact' className="btn">Let's talk</a>
                    </div>
                </div>
            </section>
        </>
    );
};
 
export default Home;