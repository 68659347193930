import React, {useState} from "react";
import './navbar.css'
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";


const Navbar = () => {
    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const getMenuIcon = () => {
        if (isActive) {
            return <AiOutlineClose id="menu-icon" onClick={toggleMenu} />;
        } else {
            return <AiOutlineMenu id="menu-icon" onClick={toggleMenu} />;
        }
    };

    return (
        <>
            <header className='header' >
                <a href="/" className="logo">polydatum</a>
                {getMenuIcon()}

                <nav className={`navbar ${isActive ? 'active' : ''}`}>
                    <a href="/" className="active">Home</a>
                    <a href="/contact">Contact</a>
                </nav>
            </header>
        </>
    );
};
 
export default Navbar;